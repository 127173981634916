import {createApp} from 'vue';
import { createWebHistory, createRouter } from "vue-router";
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import Homepage from "./pages/Homepage";

import './assets/styles/tailwind.css';

// Vue.config.productionTip = false
// Vue.use(VueRouter);
// Vue.use(Vuelidate);

const routes = [
    {path: '/', component: Homepage },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const app = createApp(App);

const messages = {
    en: {

    }
};

const i18n = createI18n({
    locale: 'en',
    messages
});

app.config.devtools = true;
app.use(router);
app.use(i18n);

app.mount('#app');


