<template>
  <div class="min-h-screen bg-gray-50 flex flex-col gap-4 justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md md:w-full">
      <h1 class="text-center text-3xl font-extrabold text-gray-500">
        HTTPAPI.io
      </h1>
    </div>

    <div class="sm:mx-auto sm:w-full sm:max-w-4xl grid grid-cols-1 md:w-full md:grid-cols-3 gap-4">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div class="">
          <json-forms
              :data="data"
              :renderers="renderers"
              :schema="schema"
              :uischema="uiSchema"
              @change="onChange"
          />
        </div>
        <div class="mt-6">
          <json-forms
              v-if="data.category"
              :data="typeData[data.category]"
              :renderers="renderers"
              :schema="typeSchemas[data.category]"
              :uischema="typeUiSchemas[data.category]"
              @change="onChangeSub"
          />
        </div>
      </div>
      <div class="col-span-2">
        <div class="w-60"></div>
        <div v-if="requestUrl" class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <h3 class="text-lg leading-6 font-medium font-extrabold text-gray-500">
            API Url:
          </h3>
          <div class="my-6 rounded-xl overflow-hidden bg-gray-800">
            <pre class="scrollbar-none overflow-x-auto text-left text-white p-4">
<code class="inline-block scrolling-touch">
{{ requestUrl }}
</code>
            </pre>
          </div>
        </div>
        <div v-if="response" class="bg-white mt-6 py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <h3 class="text-lg leading-6 font-medium font-extrabold text-gray-500">
            Response:
          </h3>
          <div class="my-6 rounded-xl overflow-hidden bg-gray-800">
            <pre class="scrollbar-none overflow-x-auto text-left text-white p-4">
<code class="inline-block scrolling-touch">
{{ response.data }}
</code>
            </pre>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full">
      <div class="flex justify-center text-gray-400">
        Proudly powered by <a href="https://www.thedevelopersnetwork.nl/?ref=httpapi.io">
        <img class="ml-1 h-6 w-auto"
             src="https://tdn-assets.tdncdn.net/logos/tdn-logo-light-no-subline.svg"
             alt="The Developers Network"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import {JsonForms} from "@jsonforms/vue";
import {
  defaultStyles,
  mergeStyles,
  vanillaRenderers,
} from "@jsonforms/vue-vanilla";
import axios from "axios";
// mergeStyles combines all classes from both styles definitions into one
const myStyles = mergeStyles(defaultStyles, {
  control: {
    label: 'block text-sm font-medium text-gray-700',
    input: 'mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md',
    error: 'mt-2 text-sm text-red-600',
    select: 'mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md',
  },
  verticalLayout: {
    root: 'flex flex-col gap-4',
    item: ''
  },
  horizontalLayout: {
    root: 'flex flex-row gap-4',
    item: ''
  },
});
const renderers = [
  ...vanillaRenderers,
  // here you can add custom renderers
];
const schema = {
  properties: {
    category: {
      type: "string",
      enum: ["ip", "dns", "uuid"]
    },
  },
};
const uiSchema = {
  type: "Control",
  scope: "#/properties/category",
};

const typeSchemas = {
  ip: {
    properties: {
      type: {
        type: "string",
        enum: ["ip", "ipv4", "ipv6"]
      },
      format: {
        type: "string",
        enum: ["", "json", "xml"]
      },
    },
  },
  dns: {
    required: [
      "domain"
    ],
    properties: {
      domain: {
        type: "string",
        minLength: 3,
      },
      record_type: {
        type: "string",
        enum: ["A", "AAAA", "CNAME", "TXT", "MX", "CAA"]
      },
      format: {
        type: "string",
        enum: ["", "json", "xml"]
      },
    },
  },
  uuid: {
    properties: {
      type: {
        type: "string",
        enum: ["", "v1", "v4"]
      },
      format: {
        type: "string",
        enum: ["", "json", "xml"]
      },
    },
  }
};
const typeUiSchemas = {
  ip: {
    "type": "VerticalLayout",
    "elements": [
      {
        type: "Control",
        scope: "#/properties/type",
      },
      {
        type: "Control",
        scope: "#/properties/format",
        rule: {
          effect: "SHOW",
          condition: {
            scope: "#/properties/type",
            schema: {
              minLength: 1,
            }
          }
        }
      },
    ]
  },
  dns: {
    "type": "VerticalLayout",
    "elements": [
      {
        type: "Control",
        scope: "#/properties/domain",
        options: {
          placeholder: "example.com"
        }
      },
      {
        type: "Control",
        scope: "#/properties/record_type",
        rule: {
          effect: "SHOW",
          condition: {
            scope: "#/properties/domain",
            schema: {
              minLength: 3,
            }
          }
        }
      },
      {
        type: "Control",
        scope: "#/properties/format",
        rule: {
          effect: "SHOW",
          condition: {
            scope: "#/properties/domain",
            schema: {
              minLength: 3,
            }
          }
        }
      },
    ]
  },
  uuid: {
    "type": "VerticalLayout",
    "elements": [
      {
        type: "Control",
        scope: "#/properties/type",
      },
      {
        type: "Control",
        scope: "#/properties/format",
        rule: {
          effect: "SHOW",
          condition: {
            scope: "#/properties/type",
          }
        }
      },
    ]
  }
};

const typeRequestInfo = {
  ip: {
    url: "https://{type}.httpapi.io/{?format}"
  },
  dns: {
    url: "https://api.httpapi.io/v1/dns/resolve/{domain}/{record_type}/{?format}"
  },
  uuid: {
    url: "https://api.httpapi.io/v1/uuid/{?type}/{?format}"
  },
};


export default defineComponent({
  components: {
    JsonForms,
  },
  data() {
    return {
      // freeze renderers for performance gains
      renderers: Object.freeze(renderers),
      data: {
        category: "ip",
      },
      typeData: {
        ip: {
          type: "ip",
          format: ""
        },
        dns: {
          record_type: "A",
          domain: "",
          format: ""
        },
        uuid: {
          type: "",
          format: ""
        },
      },
      response: null,
      schema,
      uiSchema,
      typeSchemas,
      typeUiSchemas,
      typeRequestInfo
    };
  },
  computed: {
    requestUrl: function () {
      if (!this.data.category) {
        return null
      }

      // Build url
      let urlTemplate = this.typeRequestInfo[this.data.category].url;
      for (const key in this.typeData[this.data.category]) {
        if (!this.typeData[this.data.category][key]) {
          continue;
        }

        urlTemplate = urlTemplate.replace("{" + key + "}", this.typeData[this.data.category][key]);
        urlTemplate = urlTemplate.replace("{?" + key + "}", this.typeData[this.data.category][key]);
      }

      return urlTemplate;
    },
  },
  watch: {
    requestUrl: function () {
      this.response = null;
      this.getResponse();
    }
  },
  methods: {
    onChange(event) {
      this.data = event.data;
      this.getResponse();
    },
    onChangeSub(event) {
      this.typeData[this.data.category] = event.data;
    },
    getResponse() {
      let requestUrl = this.requestUrl;
      // Check if url still hash required parameters
      if (requestUrl && requestUrl.match(/{(?!\?)\w+}/)) {
        return
      }

      // Strip optional parameters and double slashes from url
      requestUrl = requestUrl.replace(/{\?\w+}/, '');
      requestUrl = requestUrl.replace(/(?<!:)\/{2,}/, '/');
      requestUrl = requestUrl.replace(/\/$/, '');

      axios
          .get(requestUrl)
          .then(response => {
            this.response = response
          })
    }
  },
  provide() {
    return {
      styles: myStyles,
    };
  },
});
</script>
