<template>
  <div id="app">
    <main>
        <RouterView></RouterView>
    </main>
  </div>
</template>

<script>
import RouterView from 'vue-router'

export default {
  name: 'app',
  components: {RouterView},
}
</script>

